export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/opt/build/repo/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: () => import('/opt/build/repo/src/pages/[...all].vue'),
    /* no children */
    meta: {
      "layout": 404
    }
  },
  {
    path: '/btc1000',
    name: '/btc1000',
    component: () => import('/opt/build/repo/src/pages/btc1000.vue'),
    /* no children */
  },
  {
    path: '/join',
    name: '/join',
    component: () => import('/opt/build/repo/src/pages/join.vue'),
    /* no children */
  },
  {
    path: '/live',
    name: '/live',
    component: () => import('/opt/build/repo/src/pages/live.vue'),
    /* no children */
  },
  {
    path: '/miss-w',
    name: '/miss-w',
    component: () => import('/opt/build/repo/src/pages/miss-w.vue'),
    /* no children */
  },
  {
    path: '/sponsors',
    name: '/sponsors',
    component: () => import('/opt/build/repo/src/pages/sponsors.vue'),
    /* no children */
  },
  {
    path: '/vote',
    name: '/vote',
    component: () => import('/opt/build/repo/src/pages/vote.vue'),
    /* no children */
  }
]
